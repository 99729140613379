<template>
  <div class="view-overlay px-8 pt-7">
    <router-link
      to="/admin"
      tag="i"
      class="ri-arrow-left-line hov-scale hov-fade"
    ></router-link>
    <h2 class="mb-5 mt-7">Event</h2>
    <label>Event Info</label>
    <input
      v-model="event.name"
      class="w-100 mt-3"
      placeholder="Name"
      type="text"
    />
    <input
      v-model="event.eid"
      class="w-100 mt-3"
      placeholder="EID"
      type="text"
    />

    <input
      v-model="event.title"
      class="mt-3 w-100"
      placeholder="Event title"
      type="text"
    />
    <label class="mt-5">Event companies</label>

    <form @submit.prevent="addOrgToEvent()" class="mt-3">
      <div class="input-group position-relative">
        <input
          v-model="eventOrgSearch"
          class="w-100"
          placeholder="Company (oid)"
          type="text"
        />
        <button class="btn btn-sm btn-black input-btn" type="submit">
          Add
        </button>
      </div>

      <div v-if="eventOrgs" class="row no-gutters align-items-center">
        <span
          v-for="(org, index) in eventOrgs"
          :key="index"
          @click="removeOrgFromEvent(org, index)"
          class="badge badge-success mr-2 mt-2 cursor-pointer"
          >{{ org.name }}</span
        >
      </div>
    </form>

    <button @click="submitForm()" class="btn btn-black btn-block mt-5">
      Send
    </button>
  </div>
</template>

<script>
import { ADD, db, GET, UPDATE } from "@/firebase.js";

export default {
  name: "Creator",
  data: function () {
    return {
      event: {
        name: null,
        eid: null,
        title: null,
      },
      eventOrgs: [],
      removedEventOrgs: [],
      eventOrgSearch: null,
      prefill: false,
    };
  },
  methods: {
    removeOrgFromEvent: function (org, index) {
      this.removedEventOrgs.push(org);
      this.eventOrgs.splice(index, 1);
    },
    addOrgToEvent: function () {
      let col = db.collection("orgs");
      col = col.where("oid", "==", this.eventOrgSearch);
      GET(col).then((orgs) => {
        if (orgs.empty) return alert("Org does not exist");
        let org = orgs.docs[0];
        if (this.eventOrgs.some((eventOrg) => eventOrg.id === org.id)) return;
        this.eventOrgs.push({
          id: org.id,
          name: org.data().name,
        });
      });
    },
    submitForm: async function () {
      await this.updateOrgEventRSVPInDB();
      await this.updateOrgEventRSVPInDB(false);
      if (this.prefill) this.updateEvent();
      else this.addEvent();
    },
    updateOrgEventRSVPInDB: function (joinEvent = true) {
      return new Promise((resolve) => {
        let updateNum = 0;
        let list = joinEvent ? this.eventOrgs : this.removedEventOrgs;
        list.forEach((org) => {
          let doc = db.doc("orgs/" + org.id);
          let eventKey = "events." + this.event.id;
          UPDATE(doc, {
            [eventKey]: joinEvent,
          }).then(() => {
            updateNum++;
            if (updateNum === list.length) resolve(true);
          });
        });
      });
    },
    addEvent: function () {
      let col = db.collection("events");
      ADD(col, this.event).then(this.returnToAdmin);
    },
    updateEvent: function () {
      let doc = db.doc("events/" + this.event.id);
      UPDATE(doc, this.event).then(this.returnToAdmin);
    },
    returnToAdmin: function () {
      this.$router.push({
        path: "/admin",
      });
    },
    prefillData: function () {
      this.prefill = true;
      let doc = db.doc("events/" + this.$route.query.id);
      GET(doc).then((event) => {
        this.event = event.data();
        this.event.id = event.id;
        this.prefillOrgs();
        console.log(this.event);
      });
    },
    prefillOrgs: function () {
      let col = db.collection("orgs");
      col = col.where("events." + this.event.id, "==", true);
      GET(col).then((orgs) => {
        orgs.forEach((org) => {
          this.eventOrgs.push({
            id: org.id,
            name: org.data().name,
          });
        });
      });
    },
  },
  mounted: function () {
    if (this.$route.query.id) this.prefillData();
    else console.log("no id found");
  },
};
</script>